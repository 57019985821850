<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="移动护理系统" describe="医疗移动护理系统是借助移动信息技术，专为医疗护理工作打造的创新型应用系统，旨在打破传统护理工作的空间限制，让护理流程更便捷、高效。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/mobileNursing.png" class="img1" alt="">
        <!-- <img src="@/assets/product/mobileNursing2.png" class="img2" alt="">
        <img src="@/assets/product/mobileNursing3.png" class="img3" alt=""> -->
      </div>
      <div class="product-name">
        <span>解决方案</span>
      </div>
      <div class="tabs-product">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="移动护理PC" name="first" />
          <el-tab-pane label="移动护理PDA" name="second" />
        </el-tabs>
      </div>
      <div class="tab-content" :style="tabStyle">
        <div ref="mySwiper" :options="swiperOption">
          <div v-if="activeName === 'first'" class="swiper_slide_item">
            <img src="@/assets/product/mobileNursing1.png" class="img1" alt="">
          </div>
          <div v-else class="swiper_slide_item">
            <img src="@/assets/product/mobileNursing2.png" class="img2" alt="">
            <img src="@/assets/product/mobileNursing3.png" class="img3" alt="">
          </div>
        </div>
        <!-- <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide class="swiper_slide_item">
            <img src="@/assets/product/mobileNursing1.png" class="img1" alt="">
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <img src="@/assets/product/mobileNursing2.png" class="img2" alt="">
            <img src="@/assets/product/mobileNursing3.png" class="img3" alt="">
          </swiper-slide>
        </swiper> -->
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner';
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
export default {
  name: '',
  components: {
    Banner
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      activeName: 'first',
      swiperOption: {
        direction: 'horizontal',
        simulateTouch: false
      }
    };
  },
  computed: {
    tabStyle() {
      return {
        height: this.activeName === 'first' ? '560px' : '1126px'
      };
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClick() {
      const index = this.activeName === 'first' ? 0 : 1;
      this.swiper.slideTo(index, 700, false);
    }
  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1127px;
    margin: 0 auto;
    .img1 {
      width: 100%;
      height: 588px;
    }
  }
  .tabs-product {
    width: 244px;
    margin: 0 auto;
    margin-bottom: 80px;
    /deep/.el-tabs {
      .el-tabs__nav {
        width: 100%;
        .el-tabs__item {
          padding: 0;
          text-align: center;
          min-width: 122px;
          // padding: 0 36px !important;
          font-size: 16px;
          color: #333;
          font-weight: normal;
          height: 56px;
          line-height: 56px;
          &.is-active {
            color: #0279FF;
          }
        }
        .el-tabs__active-bar {
          color: #0279FF;
        }
      }
    }
  }
  .tab-content {
    width: 1164px;
    margin: 0 auto;
    height: 560px;
    .swiper_slide_item {
      text-align: center;
    }
    .img1 {
      width: 1164px;
      height: 560px;
    }
    .img2 {
      width: 1076px;
      height: 524px;
      margin-bottom: 100px;
    }
    .img3 {
      width: 1169px;
      height: 501px;
    }
  }
}
</style>
